<script>
import { global } from './utils'
import { BIconShareFill } from 'bootstrap-vue'

export default {
  name: 'Event',
  props: {
    user: [Object, String],
    campaignProp: Object,
    categoriesProp: Array,
    language: String,
  },
  components: {
    BIconShareFill,
  },
  data() {
    return {
      global,
      event: {},
      categories: [],
      reviewComment: '',
      popularTags: [],
      editorImage: null,
      showEventTickets: false,
    }
  },
  methods: {
    getMetaByKey(meta, key) {
      if (Object.prototype.hasOwnProperty.call(meta, key)) {
        return JSON.parse(JSON.stringify(meta[key]))
      } else {
        return false
      }
    },
    isValidUrl(value) {
      if (
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#()?&//=]*)/.test(
          value
        )
      ) {
        return true
      } else {
        return false
      }
    },
    formatDateFromString(dateString, locale) {
      const date = new Date(dateString)
      return (
        dateString &&
        new Intl.DateTimeFormat(locale, { dateStyle: 'short' }).format(date)
      )
    },
    formatHourFromString(hourString, locale) {
      const date = new Date('1970-01-01T' + hourString)
      return new Intl.DateTimeFormat(locale, {
        hour: 'numeric',
        minute: 'numeric',
      }).format(date)
    },
    sortTickets(sourceTickets, sortKey, sortDirection) {
      const arrayClone = JSON.parse(JSON.stringify(sourceTickets))
      return arrayClone.sort((a, b) => {
        if (sortKey === 'amount') {
          if (sortDirection === 'asc') {
            return a.amount - b.amount
          } else {
            return b.amount - a.amount
          }
        } else {
          return 0
        }
      })
    },
  },
  computed: {
    authHeader() {
      return this.user && this.user.token && 'Bearer ' + this.user.token
    },
    loggedIn() {
      return this.user ? true : false
    },
    mainImageSrc() {
      return this.event.main_image
        ? this.global.getCdnImage(this.event.main_image, 1200, 630)
        : null
    },
    mainImageCaption() {
      return this.event.meta && this.event.meta.mainImageCaption
    },
    isUserEditor() {
      return (
        (this.user && this.user.group_id === '1') ||
        (this.event.scope && this.event.scope.includes('edit'))
      )
    },
    isUserAdmin() {
      return (
        (this.user && this.user.group_id === '1') ||
        (this.event.scope && this.event.scope.includes('admin'))
      )
    },
    eventTitle() {
      return (
        this.event.title &&
        this.event.title[this.language] &&
        this.event.title[this.language].value
      )
    },
    eventShortDescription() {
      return (
        this.event.short_description &&
        this.event.short_description[this.language] &&
        this.event.short_description[this.language].value
      )
    },
    eventLongDescription() {
      return (
        this.event.long_description &&
        this.event.long_description[this.language] &&
        this.event.long_description[this.language].value &&
        this.event.long_description[this.language].value !== '<p></p>' &&
        this.event.long_description[this.language].value
      )
    },
    eventLongDescriptionPreviewValue() {
      return (
        (this.eventLongDescriptionPreview[this.language] &&
          this.eventLongDescriptionPreview[this.language].value) ||
        ''
      )
    },
    eventTagline() {
      return (
        this.event.tagline &&
        this.event.tagline[this.language] &&
        this.event.tagline[this.language].value
      )
    },
    eventLocationUrl() {
      return (
        this.event.meta &&
        this.event.meta.location &&
        this.event.meta.location.url &&
        this.isValidUrl(this.event.meta.location.url) &&
        this.event.meta.location.url
      )
    },
    eventLocation() {
      return (
        this.event.meta &&
        this.event.meta.location &&
        this.event.meta.location.text
      )
    },
    eventExternalLinkUrl() {
      return (
        this.event.meta &&
        this.event.meta.externalLink &&
        this.event.meta.externalLink.url &&
        this.isValidUrl(this.event.meta.externalLink.url) &&
        this.event.meta.externalLink.url
      )
    },
    eventExternalLinkText() {
      return (
        this.event.meta &&
        this.event.meta.externalLink &&
        this.event.meta.externalLink.text
      )
    },
    eventTicketLinkUrl() {
      return (
        this.event.meta &&
        this.event.meta.ticketLink &&
        this.event.meta.ticketLink.url &&
        this.isValidUrl(this.event.meta.ticketLink.url) &&
        this.event.meta.ticketLink.url
      )
    },
    eventStartDate() {
      return (
        this.event.meta && this.event.meta.date && this.event.meta.date.start
      )
    },
    eventDateHour() {
      return (
        this.event.meta && this.event.meta.date && this.event.meta.date.hour
      )
    },
    eventEndDate() {
      return this.event.meta && this.event.meta.date && this.event.meta.date.end
    },
    eventDateType() {
      return (
        this.event.meta && this.event.meta.date && this.event.meta.date.type
      )
    },
    localeCode() {
      // if (this.language.toLowerCase() === 'en') {
      //   return 'en-US'
      // }
      // if (this.language.toLowerCase() === 'is') {
      //   return 'is' // NOTE: will not work in Chrome, icelandic locale is not supported
      // }
      // default fallback
      return 'en-GB'
    },
    eventStage() {
      return this.event.stage
    },
    eventCategories() {
      return this.event.categories
    },
    eventTags() {
      return this.event.meta && this.event.meta.tags
    },
    eventUrlType() {
      if (window.location.pathname.indexOf('/events/') !== -1) {
        if (window.location.pathname.indexOf('/admin') !== -1) {
          return 'admin'
        }
        if (window.location.pathname.indexOf('/edit') !== -1) {
          return 'editor'
        }
        return 'view'
      }
      return false
    },
    eventHasTickets() {
      return this.event.tickets && this.event.tickets.length > 0
    },
    isEventExternalLinkUrlFacebook() {
      return (
        this.eventExternalLinkUrl &&
        /^(https?:\/\/)?(www\.)?facebook.com\/[a-zA-Z0-9(.?)?]/.test(
          this.eventExternalLinkUrl
        )
      )
    },
  },
  mounted() {
    this.event = JSON.parse(JSON.stringify(this.campaignProp))
    this.categories = JSON.parse(JSON.stringify(this.categoriesProp))
  },
}
</script>

<style lang="scss">
.editor-content img {
  max-width: 100%;
  height: auto;
}
.karolinafund-widget {
  background-color: #f6faff;
  border: 1px solid #a9c2e5;
}
</style>
